.ofelos-pay-section {

    .ofelos-pay-inner {
        box-sizing: border-box;
        position: relative;
        max-width: 1150px;
        padding: 50px 25px;
        margin: auto;

        @include apply-to(less-than, $mobile) {
            padding: 20px 15px 0;
        }
    }
}
