.ofelos-pay-container-text {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;

    @include apply-to(less-than, $mobile) {
        margin-bottom: 10px;
    }
}

.ofelos-pay-input-file {
    display: none;
}

.ofelos-pay-checkbox {
    display: flex;
    align-items: center;
    margin-right: 25px;
    -webkit-tap-highlight-color: transparent;

    @include apply-to(less-than, $mobile) {
        margin-right: 0;
        margin-top: 18px;
        margin-bottom: 18px;
    }
}

.ofelos-pay-checkbox-input {
    display: none;
}

.ofelos-pay-checkbox-icon {
    position: relative;
    top: -1px;
    cursor: pointer;
    border-radius: 4px;
    display: block;
    width: 18px;
    height: 18px;
    background: url('../../images/form/checkbox.svg') no-repeat;
    background-size: 100%;
    transition: all .1s ease;
    -webkit-tap-highlight-color: transparent;

    .ofelos-pay-checkbox-input:checked + & {
        background: url('../../images/form/checkbox-checked.svg') no-repeat;
        background-size: 100%;
    }
}

.ofelos-pay-checkbox-label {
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}
