.ofelos-pay-wrapper-title {
    margin-bottom: 20px;

    @include apply-to(less-than, $mobile) {
        display: flex;
        align-items: center;

    }
}

.ofelos-pay-title {

    @include apply-to(less-than, $mobile) {
        margin-right: 50px;
    }

    h1 {
        font-family: "Robotobold";
        font-size: 32px;
        line-height: 38px;

        @include apply-to(less-than, $mobile) {
            font-size: 22px;
            line-height: 26px;
        }
    }
}
