@mixin font-face($font-name, $font-path,
$font-weight: 400, $font-style: normal, $font-display: swap, $support-required: 'modern') {
  @font-face {
    font-family: $font-name;
    font-style : $font-style;
    font-weight: $font-weight;
    font-display: $font-display;
    @if $support-required=='oldie' {
      src: url('#{$font-path}.eot');
      src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}.woff') format('woff'),
      url('#{$font-path}.ttf') format('truetype');
    }
  }
}

@include font-face('Roboto', '../fonts/Roboto/Roboto-Regular', normal, normal, swap, 'oldie');
@include font-face('Robotobold', '../fonts/Roboto/Roboto-Bold', normal, bold, swap, 'oldie');
