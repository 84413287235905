body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $body_background_color url('../../images/body/bg-world.png') no-repeat;
    background-size: 100%;
    color: $body_text_color;
    font-family: 'Roboto';

    @include apply-to(less-than, $mobile) {
        background: $body_background_color url('../../images/body/bg-world-mobile.png') no-repeat;
        background-size: 100%;
    }

    &.stop-scroll {

        @include apply-to(less-than, $mobile) {
            max-height: 100%;
            overflow: hidden;
        }
    }
}

a {
    color: #43ec80;
    text-decoration: none;
    transition: color .1s ease;
    -webkit-tap-highlight-color: transparent;

    &:hover {

        @include apply-to(greater-than, $mobile) {
            color: #a4ffc5;
        }
    }

    &:active {
        color: #24b859;
    }
}

#root {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.svg-default {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
}
