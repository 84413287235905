.Toastify {

    &__toast {
        width: 356px !important;
        max-width: 100% !important;
        min-height: inherit !important;
        font-size: 12px !important;
        line-height: 14px !important;
        padding: 6px 7px !important;
        color: #141419 !important;
        border: 1px solid #c9c9c9;
        border-radius: 10px !important;
        background-color: #dfdfdf !important;
        box-shadow: none !important;

        @include apply-to(less-than, $mobile) {
            width: 100% !important;
        }

        &--info {
            background-color: #dfdfdf !important;
            border-color: #c9c9c9;
        }

        &--success {
            color: #fff !important;
            background-color: #1dba5b !important;
            border-color: #119746;
        }

        &--error {
            color: #fff !important;
            background-color: #c53929 !important;
            border-color: #b13325;
        }

        &--p2p {
            color: #141419 !important;
            background-color: #fed631 !important;
            border-color: #e5c12c;
        }

        &--message {
            color: #141419 !important;
            background-color: #fed631 !important;
            border-color: #e5c12c;
        }
    }

    &__close-button {
        align-self: stretch !important;
        opacity: .3 !important;
        margin: -20px -10px -20px 0;
        padding: 0 16px 0 16px !important;

        & > svg {
            width: 13px;
            height: 13px;
            color: #000;
        }

        &:hover, &:focus {
            opacity: .7 !important;
        }
    }

    &__notification {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__icon {
            width: 20px;
            height: 20px;
            margin-right: 14px;
            flex-shrink: 0;
        }

        &__content {

            &--link {
                color: #262009 !important;
            }
        }
    }

    svg {
        margin: 0;
    }
}

.Toastify__group-buttons {
    display: flex;
    margin-top: 11px;
    margin-bottom: -5px;

    button + button {
        margin-left: 7px;
    }
}

.popup-has-show {

    .smart-notifications {
        right: 6px;

        @include apply-to(less-than, $small-pc) {
            right: 0;
        }
    }
}

.Toastify {

    @include apply-to(less-than, $mobile) {
        box-sizing: border-box;
        position: fixed;
        display: flex;
        flex-direction: column;
        width: 100%;
        right: 0;
        top: 0;
        max-height: calc(100% - 20px);
        padding: 0 15px;
        z-index: 10;
    }

    .Toastify__toast-container--bottom-right {

        @include apply-to(less-than, $mobile) {
            position: relative;
            width: 100%;
            bottom: 0;
            right: 0;
            padding: 0;
        }
    }
}

.Toastify {

    @include apply-to(less-than, $mobile) {
        overflow: hidden;
    }

    .Toastify {
        margin-bottom: 20px;
        padding-right: 9px;
    
        @include apply-to(less-than, $mobile) {
            margin-bottom: 0;
            padding: 0 20px;
        }

        @media only screen and (max-width: 370px) {
            padding: 0 15px;
        }
    }

    .Toastify__toast {
        margin-bottom: 0;
    }

    .Toastify__toast:first-child {
        margin-top: 20px;

        @include apply-to(less-than, $mobile) {
            margin-top: 15px;
        }
    }

    .Toastify__toast ~ .Toastify__toast {
        margin-top: 10px;
    }
}

.toastify-scroll {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 6px 16px 0;

    @include apply-to(less-than, $mobile) {
        padding: 0 6px 7px 0;
        max-height: inherit;
    }

    &::-webkit-scrollbar {
        width: 3px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
        margin-bottom: 16px;

        @include apply-to(less-than, $mobile) {
            margin-bottom: 0;
        }
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #525252;
        border-radius: 3px;
    }

    .Toastify__toast-container--bottom-right {
        bottom: inherit;
        right: inherit;
    }

    .Toastify__toast-container {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        top: inherit;
        width: 100%;
    }

    .Toastify__toast {
        flex-shrink: 0;
        margin-bottom: 0;
        z-index: 1;
    }

    .Toastify__toast ~ .Toastify__toast {
        margin-bottom: 10px;
    }
}

.notifications-minimized {
    display: flex;
    flex-direction: column;
    width: 365px;
    margin: 0 auto;
    padding: 0;

    @include apply-to(less-than, $mobile) {
        width: 100%;
        overflow: hidden;
        padding: 0 11px 0 20px;
    }

    @media only screen and (max-width: 370px) {
        padding: 0 6px 0 15px;
    }
}

.notifications-minimized__heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2c2d2e;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, .25);
    border-radius: 10px;
    padding: 5px;
    margin-right: 9px;
    margin-bottom: 10px;

    @include apply-to(less-than, $mobile) {
        margin-top: 15px;
    }

    &-title {
        font-family: "Roboto";
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        margin-right: 30px;
        white-space: nowrap;
        margin-left: 9px;
    }

    .button-adaptive_full {
        width: auto;
        font-size: 13px;
        min-height: 31px;
        border-radius: 6px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .button-adaptive {
        white-space: nowrap;
    }

    .button-adaptive ~ .button-adaptive {
        margin-left: 5px;
    }
}

.notifications-minimized__body {
    display: flex;
    transition: max-height .5s ease;
}

.notifications-minimized {

    &.shown {

        .Toastify__toast {
            cursor: default;
        }

        .Toastify__toast:only-child {

            @include apply-to(less-than, $mobile) {
                margin-top: 15px;
            }
        }

        .notifications-minimized__body {
            max-height: 100vh;

            @include apply-to(less-than, $mobile) {
                max-height: 100%;
                overflow: hidden;
            }
        }

        .toastify-scroll {

            @include apply-to(less-than, $mobile) {
                padding-bottom: 0;
            }
        }

        .Toastify--animate.Toastify__zoom-exit {
            -webkit-animation-duration: .2s !important;
            animation-duration: .2s !important;
        }
    }

    &.hidden {
        flex-shrink: 0;

        .notifications-minimized__body {
            max-height: 269px;
        }

        .Toastify__toast {
            margin-bottom: 0;
        }

        .Toastify__toast {
            position: absolute;
            bottom: -7px;
            left: calc(50% - 161px);
            width: 316px !important;
            z-index: 0;
        }

        .Toastify__toast ~ .Toastify__toast:nth-last-child(2) {
            position: relative;
            width: 355px !important;
            left: inherit;
            bottom: inherit;
            z-index: 1;

            @include apply-to(less-than, $mobile){
                width: 100% !important;
            }
        }

        .Toastify__toast:nth-child(2) ~ .Toastify__toast:nth-last-child(1) {
            width: 355px !important;
            left: inherit;
            bottom: inherit;
            z-index: 1;

            @include apply-to(less-than, $mobile) {
                width: 100% !important;
            }
        }

        .Toastify__toast:only-child {
            position: relative;
            width: 355px !important;
            left: inherit;
            bottom: inherit;
            z-index: 1;

            @include apply-to(less-than, $mobile) {
                width: 100% !important;
                margin-top: 15px;
            }
        }

        .Toastify__toast:first-child + .Toastify__toast:last-child {
            position: relative;
            width: 355px !important;
            left: inherit;
            bottom: inherit;
            z-index: 1;

            @include apply-to(less-than, $mobile) {
                width: 100% !important;
            }
        }

        .Toastify--animate.Toastify__zoom-exit {
            -webkit-animation-duration: .7s !important;
            animation-duration: .7s !important;
        }

        .Toastify--animate {
            -webkit-animation-duration: .5s !important;
            animation-duration: .5s !important;
        }
    }
}

#modal-confirm {

    .popup {

        @include apply-to(less-than, $mobile) {
            z-index: 120 !important;
        }
    }
}
