.smart-loader {
    display: flex;
    justify-content: center;
    margin: 5px 0;

    svg {
        width: 50px;
        height: 20px;
    }
}

.svg-animate-g-1 {
    animation: 1.2s linear infinite both a0_t;
}

.svg-animate-e-1 {
    animation: 1.2s linear infinite both a1_t;
}

.svg-animate-e-2 {
    animation: 1.2s linear infinite both a2_t;
}

.svg-animate-e-3 {
    animation: 1.2s linear infinite both a3_t;
}

.svg-animate-e-4 {
    animation: 1.2s linear infinite both a4_t;
}

@keyframes a0_t {

    0% {
        transform: translate(131px,130.5px) rotate(0deg) translate(-132.5px,-132px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    25% {
        transform: translate(131px,130.5px) rotate(90deg) translate(-132.5px,-132px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }
    
    50% {
        transform: translate(131px,130.5px) rotate(180deg) translate(-132.5px,-132px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    75% {
        transform: translate(131px,130.5px) rotate(90deg) translate(-132.5px,-132px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }
    
    100% {
        transform: translate(131px,130.5px) rotate(0deg) translate(-132.5px,-132px);
    }
}

@keyframes a1_t {

    0% {
        transform: translate(61.5px,61.5px); 
        animation-timing-function: cubic-bezier(0,0,.6,1); 
    } 

    25% { 
        transform: translate(132.5px,133.5px); 
        animation-timing-function: cubic-bezier(0,0,.6,1); 
    } 

    50% { 
        transform: translate(61.5px,61.5px); 
        animation-timing-function: cubic-bezier(0,0,.6,1); 
    } 

    75% {
        transform: translate(132.5px,133.5px); 
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    100% { 
        transform: translate(61.5px,61.5px);
    } 
}

@keyframes a2_t {

    0% {
        transform: translate(201.5px,61.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    25% {
        transform: translate(132.5px,133.5px); animation-timing-function: cubic-bezier(0,0,.6,1); 
    } 

    50% {
        transform: translate(203.5px,61.5px); 
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    75% { transform: translate(132.5px,133.5px); 
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    100% { 
        transform: translate(203.5px,61.5px); 
    }
}

@keyframes a3_t {

    0% {
        transform: translate(61.5px,201.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }
    
    25% {
        transform: translate(132.5px,133.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    50% {
        transform: translate(61.5px,202.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1); 
    }
    
    75% {
        transform: translate(132.5px,133.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    100% {
        transform: translate(61.5px,202.5px);
    }
}

@keyframes a4_t {

    0% {
        transform: translate(201.5px,201.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    25% {
        transform: translate(132.5px,133.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    50% {
        transform: translate(203.5px,202.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }
    
    75% {
        transform: translate(132.5px,133.5px);
        animation-timing-function: cubic-bezier(0,0,.6,1);
    }

    100% {
        transform: translate(203.5px,202.5px);
    }
}
