.ofelos-pay-support {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #171d19;
    border-radius: 4px;
    padding: 20px 20px;
    max-width: 844px;

    @include apply-to(less-than, $mobile) {
        align-items: flex-start;
        border-radius: 0;
        padding: 20px 15px;
        margin: 0 -15px;
    }

    .icon {
        display: flex;
        width: 34px;
        height: 34px;
        margin-right: 13px;
        flex-shrink: 0;

        svg {
            fill: #43ec80;
        }
    }

    .description {
        font-size: 14px;
        line-height: 17px;
        max-width: 540px;
        word-break: break-word;

        p ~ p {
            margin-top: 8px;
        }
    }
}
