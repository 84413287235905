
.ofelos-pay-loader-screenshot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1e2621;
    padding: 12px 22px;
    text-align: left;

    @include apply-to(less-than, $mobile) {
        align-items: baseline;
        flex-direction: column;
        padding: 17px 17px 15px;
    }
}

.ofelos-pay-loader-screenshot-description {
    display: flex;

    .icon {
        display: block;
        width: 28px;
        height: 32px;
        margin-right: 12px;
        flex-shrink: 0;
    }

    .description {
        line-height: 17px;
    }
}

.ofelos-pay-loader-screenshot-button {
    position: relative;
    min-width: fit-content;
    margin-left: 20px;

    @include apply-to(less-than, $mobile) {
        margin-left: 40px;
        margin-top: 15px;
    }

    .smart-loader {
        width: 100%;
    }
}
