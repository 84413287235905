.ofelos-pay-payment-system {
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135px;
    height: 180px;
    margin-right: 25px;
    background-color: $payment_system_avatar_background_color;
    flex-shrink: 0;

    @include apply-to(less-than, $mobile) {
        width: 100%;
        height: 98px;
        margin-right: 0;
        margin-bottom: 10px;
    }

    img {
        max-width: 74px;
        max-height: 68px;
    }
}

.ofelos-pay-payment-system-bonus {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    top: 0;
    right: 0;
    margin: -10px;
    z-index: 1;

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .total {
        font-family: Robotobold;
        font-size: 14px;
        color: #fff;
    }
}

.ofelos-pay-timer-deposit {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 18px;

    @include apply-to(less-than, $mobile) {
        margin-bottom: 20px;
    }

    .timer {
        margin: 0 5px;
    }

    b + b {
        margin-left: 5px;
    }

    b:last-child {
        margin-right: 2px;
    }

    svg {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 5px;
        position: relative;
        top: 1px;
        margin-left: 3px;
    }
}

.ofelos-pay-card-transfer {
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    background-color: #171d19;
    padding-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;

    @include apply-to(less-than, $mobile) {
        margin-bottom: 0;
    }

    .title {
        display: inline-block;
        font-family: Robotobold;
        margin-bottom: 5px;
    }

    .total {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
    }
}

.ofelos-pay-columns {
    display: flex;
    padding: 0 20px 15px;

    @include apply-to(less-than, $mobile) {
        flex-direction: column;
        padding: 0 15px 20px;
    }
}

.ofelos-pay-column {
    display: flex;
    flex-direction: column;

    &:first-child {
        width: 130px;

        @include apply-to(less-than, $mobile) {
            width: 100%;
        }
    }

    &:last-child {
        margin-left: 8%;

        @include apply-to(less-than, $mobile) {
            margin-left: 0;
        }
    }
}

.ofelos-pay-column + .ofelos-pay-column {

    @include apply-to(less-than, $mobile) {
        margin-top: 20px;
    }
}

.ofelos-pay-confirm-transfer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include apply-to(less-than, $mobile) {
        flex-direction: column;
        margin-top: 15px;
    }

    .ofelos-pay-status-message + & {
        margin-top: 15px;
    }

    .ofelos-pay-button ~ .ofelos-pay-button {
        margin-left: 15px;

        @include apply-to(less-than, $mobile) {
            margin-left: 0;
            margin-top: 15px;
        }
    }
}

.ofelos-pay-status-message {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include apply-to(less-than, $mobile) {
        flex-direction: column;
        margin-top: 15px;
    }

    .description {
        font-size: 14px;
        line-height: 17px;

        @include apply-to(less-than, $mobile) {
            margin-bottom: 15px;
        }
    }

    .ofelos-pay-label-button {
        margin-left: 15px;

        @include apply-to(less-than, $mobile) {
            margin-left: 0;
            width: 100%;
            justify-content: center;
        }
    }

    &.box {
        justify-content: space-between;
        background-color: #1e2621;
        padding: 10px 20px;

        @include apply-to(less-than, $mobile) {
            padding: 15px;
            align-items: flex-start;
        }

        .ofelos-pay-label-button {
    
            @include apply-to(less-than, $mobile) {
                width: inherit;
            }
        }
    }
}

.box .ofelos-pay-label-button {
    background-color: #171d19;
}