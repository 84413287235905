.ofelos-pay-container {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
    background-color: $container_background_color;
    max-width: 844px;
    box-shadow: 0px 33px 70px #112c23;
    margin-bottom: 30px;

    @include apply-to(less-than, $mobile) {
        border-radius: 0;
        padding: 15px 15px 25px;
        margin: 0 -15px;
    }
}

.ofelos-pay-container-inner {
    display: flex;

    @include apply-to(less-than, $mobile) {
        flex-direction: column;
    }
}

.ofelos-pay-wrapper-container {
    flex-grow: 1;
}
