.ofelos-pay-popup {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px;
    overflow: auto;
    z-index: 10;

    @include apply-to(less-than, $mobile) {
        padding: 10px;
    }
}

.ofelos-pay-popup-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #0f1210;
    padding: 50px;
    width: 425px;
    margin: auto;

    @include apply-to(less-than, $mobile) {
        width: 100%;
        padding: 40px;
    }
}

.ofelos-pay-popup-description {
    font-size: 16px;
    line-height: 19px;
    font-family: Robotobold;
    margin-bottom: 25px;
}

.ofelos-pay-popup-buttons {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 0 10px;

    @include apply-to(less-than, $mobile) {
        padding: 0;
    }

    .ofelos-pay-button {
        width: 100%;
        margin: 0 7.5px;
        text-align: center;
    }
}

.ofelos-pay-popup-button-close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    $size: 15px;
    width: $size;
    height: $size;
    padding: 5px;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;

    svg {
        fill: #363640;
    }
}
