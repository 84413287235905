.ofelos-pay-breadcrumb {
    margin-bottom: 5px;

    @include apply-to(less-than, $mobile) {
        position: absolute;
        right: 15px;
        margin-bottom: 0;
        margin-top: -1px;
    }
}

.ofelos-pay-breadcrumb-item {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    padding: 1px 5px;
    margin: -1px -5px;
    color: #43ec80;
    -webkit-tap-highlight-color: transparent;
    transition: all .1s ease;
    cursor: pointer;

    .icon {
        position: relative;
        display: block;
        width: 5px;
        height: 8px;
        margin-right: 8px;
    }

    svg {
        fill: #43ec80;
        transition: all .1s ease;
    }

    &:hover {
        color: #a4ffc5;

        svg {
            fill: #a4ffc5;
        }
    }

    &:active {
        color: #24b85a;

        svg {
            fill: #24b85a;
        }
    }
}
