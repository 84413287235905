.ofelos-pay-header {
    border-bottom: 1px solid $header_border_color;

    .ofelos-pay-inner {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1150px;
        padding: 0 25px;
        margin: auto;
        height: 87px;

        @include apply-to(less-than, $mobile) {
            padding: 0 15px;
            height: 60px;
        }
    }
}

.ofelos-pay-logotype {
    width: 153px;
    height: 35px;
    transition: opacity ease .1s;

    @include apply-to(less-than, $mobile) {
        width: 122px;
        margin: 0 auto;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.ofelos-pay-menu-mobile {
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    z-index: 5;

    @include apply-to(less-than, $mobile) {
        display: flex;
    }
}

.button-open-menu {
    position: relative;
    $wh: 24px;
    width: $wh;
    height: $wh;

    &:active {
        opacity: .5;
    }

    img {
        position: absolute;
        top: 0;
        width: 100%;
        transition: all ease .3s;
    }

    .close-svg {
        opacity: 0;
    }

    &.active {
        
        .open-svg {
            opacity: 0;
        }

        .close-svg {
            opacity: 1;
        }    
    }
}

.ofelos-pay-menu {
    display: flex;
    align-items: center;

    @include apply-to(less-than, $mobile) {
        opacity: 0;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        visibility: hidden;
        background-color: $menu_background_color_mobile;
        padding: 105px 28px;
        transition: opacity .3s, visibility .75s, width .3s;
        overflow: hidden;
        z-index: 3;
    }

    &.active {

        @include apply-to(less-than, $mobile) {
            opacity: 1;
            visibility: visible;
            width: 100vw;
        }
    }

    &__item {
        position: relative;
        top: 3px;
        font-family: 'Gotham Medium';
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1px;
        color: $menu_color_link;
        text-decoration: none;
        text-transform: uppercase;
        border-bottom: 2px solid transparent;
        padding-bottom: 4px;
        transition: all ease .3s;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;

        @include apply-to(less-than, $mobile) {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 1px;
            white-space: nowrap;
        }

        &:hover {

            @include apply-to(greater-than, $mobile) {
                border-color: $menu_border_color;
            }
        }

        &:active {
            border-color: $menu_border_color_hover;
            color: $menu_color_link_hover;
        }
    }

    &__item + &__item {
        margin-left: 50px;

        @include apply-to(less-than, $mobile) {
            margin-left: 0;
            margin-top: 30px;
        }
    }
}
