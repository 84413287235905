.ofelos-pay-steps {
    display: flex;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: -7.5px;
    margin-right: -7.5px;
    max-width: 859px;

    @include apply-to(less-than, $mobile) {
        flex-direction: column;
        margin-top: 55px;
        margin-left: 0;
        margin-right: 0;
    }
}

.ofelos-pay-step {
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: calc(33.33% - 15px);

    @include apply-to(less-than, $mobile) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;    
    }

    .body {
        position: relative;
        background-color: #171d19;
        border-radius: 4px;
        padding-top: 35px;
    }

    .number {
        background-color: #101411;
        border-radius: 50px;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        font-family: "Robotobold";
        font-size: 24px;
        color: #979797;
        z-index: 1;

        &.active {
            background-color: #43ec80;
            color: #0f0f0f;
        }

        svg {
            width: 24px;
            fill: #43ec80;
        }
    }

    .title {
        font-family: "Robotobold";
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        text-align: center;
        padding: 0 30px 15px;

        &:last-child {
            padding-bottom: 25px;
        }
    }

    .description {
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        text-align: center;
        padding: 0 40px 25px;
    }
}

.ofelos-pay-step + .ofelos-pay-step {

    @include apply-to(less-than, $mobile) {
        margin-top: 45px;
    }
}

.ofelos-pay-step-screenshot {
    display: flex;
    align-items: center;
    margin-top: 2px;
    padding: 0 17px 10px;

    .icon {
        width: 28px;
        height: 32px;
        margin-right: 12px;
        flex-shrink: 0;

        .icon-done {
            display: none;
        }

        &.active {

            .icon-timer {
                display: none;
            }

            .icon-done {
                display: block;
            }
        }
    }

    .text {
        font-family: "Roboto";
        font-size: 14px;
        line-height: 17px;

        @include apply-to(less-than, $mobile) {
            max-width: 190px;
        }
    }
}

.ofelos-pay-step-support {
    display: flex;
    align-items: center;
    padding: 8px 19px 10px;
    border-top: 5px solid #0f0f0f;

    @include apply-to(less-than, $mobile) {
        padding: 10px 20px 11px;
    }

    svg {
        fill: #45544a;
    }

    .icon {
        width: 25px;
        height: 24px;
        color: #363640;
        margin-right: 15px;
        flex-shrink: 0;
    }

    .text {
        font-family: "Roboto";
        font-size: 14px;
        line-height: 17px;

        @include apply-to(less-than, $mobile) {
            max-width: 190px;
        }    

        a {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}
