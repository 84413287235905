.ofelos-pay-copy-button {
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    line-height: 16px;
    color: #43ec80;
    margin-right: auto;
    transition: all ease .1s;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    .svg-default {
        width: 13px;
        height: 13px;
        margin-right: 5px;
        fill: currentColor;
    }

    &:hover {

        @include apply-to(greater-than, $mobile) {
            color: #a4ffc5;
        }
    }

    &:active {
        color: #24b85a;
    }
}

.ofelos-pay-button-upload {
    cursor: pointer;
    border-radius: 10px;
    background-color: transparent;
    display: inline-block;
    height: 30px;
    line-height: 28px;
    padding: 0 13px;
    font-size: 14px;
    color: #43ec80;
    border: 1px solid #43ec80;
    transition: all .1s ease;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &:hover {

        @include apply-to(greater-than, $mobile) {
            background-color: #a4ffc5;
            border-color: #a4ffc5;
            color: #000;
        }
    }

    &:active {
        background-color: #24b859;
        border-color: #24b859;
        color: #000;
    }

    &.disabled {
        border-color: #38413b;
        color: #38413b;
        pointer-events: none;
    }
}

.ofelos-pay-button {
    cursor: pointer;
    border-radius: 4px;
    background-color: #43ec80;
    box-shadow: 0 10px 50 rgba(17, 44, 35, .4);
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    font-size: 14px;
    font-family: Robotobold;
    color: #141419;
    transition: all .1s ease;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    @include apply-to(less-than, $mobile) {
        width: 100%;
        text-align: center;
    }

    &:hover {

        @include apply-to(greater-than, $mobile) {
            background-color: #a4ffc5;
        }
    }

    &:active {
        background-color: #24b85a;
    }

    &.disabled {
        background-color: #171d19;
        color: #363e37;
        pointer-events: none;
    }

    &.cancel {
        background-color: #1a2f21;
        color: #5a8f6e;

        &:hover {

            @include apply-to(greater-than, $mobile) {
                background-color: #2b5339;
                color: #5da879;
            }
        }
    
        &:active {
            background-color: #14271a;
            color: #244b32;
        }

        &.disabled {
            background-color: #171d19;
            color: #363e37;
            pointer-events: none;
        }
    }
}

.ofelos-pay-label-button {
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #171d19;
    height: 40px;
    padding: 0 17px;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    .icon {
        $size_icon: 14px;
        width: $size_icon;
        height: $size_icon;
        margin-right: 6px;
    }

    .name {
        font-family: Robotobold;
        font-size: 14px;
    }
}
