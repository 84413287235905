.ofelos-pay-footer {
    margin-top: auto;

    .ofelos-pay-inner {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1150px;
        padding: 0 25px;
        margin: auto;

        @include apply-to(less-than, $mobile) {
            justify-content: center;
        }
    }
}

.ofelos-pay-copyright {
    font-size: 14px;
    line-height: 17px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #45544a;

    @include apply-to(less-than, $mobile) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}
