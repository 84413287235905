.ofelos-pay-lang {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 76px;
    user-select: none;
    z-index: 3;

    @include apply-to(less-than, $mobile) {
        position: absolute;
        right: 0;
        top: 0;
        width: 76px;
        z-index: 1;
    }

    &:hover {

        .ofelos-pay-menu-lang {
            height: 112px;
            opacity: 1;
        }

        .ofelos-pay-menu-arrow-icon {
            transform: rotateX(0);
        }
    }
}

.ofelos-pay-lang-current {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 25px;
    height: 88px;

    @include apply-to(less-than, $mobile) {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 18px;
        height: 60px;
        z-index: 1;
    }
}

.ofelos-pay-lang-country-icon {
    display: block;
    $size_icon: 26px;
    width: $size_icon;
    height: $size_icon;
    flex-shrink: 0;
}

.ofelos-pay-lang-name {
    font-size: 14px;
    color: #35ff61;
    margin-right: 35px;
    text-transform: uppercase;

    @include apply-to(less-than, $mobile) {
        margin-right: 20px;
    }
}

.ofelos-pay-menu-arrow-icon {
    position: absolute;
    display: block;
    width: 12px;
    height: 8px;
    right: 0;
    transform: rotateX(180deg);
    transition: all .3s ease;

    @include apply-to(less-than, $mobile) {
        display: none;
    }

    svg {
        fill: #0d3d1e;
    }
}

.ofelos-pay-menu-lang {
    position: absolute;
    height: 0;
    opacity: 0;
    top: 89px;
    right: 0;
    box-shadow: 0 33px 70px #112c23;
    transition: height .4s ease, opacity .5s ease;
    overflow: hidden;

    @include apply-to(less-than, $mobile) {
        top: 61px;
    }
}

.ofelos-pay-menu-lang-item {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #0f1211;
    width: 191px;
    height: 56px;
    padding: 0 25px;
    -webkit-tap-highlight-color: transparent;
    transition: all ease .1s;

    @include apply-to(less-than, $mobile) {
        padding: 0 18px;
    }

    &:hover {

        @include apply-to(greater-than, $mobile) {
            background-color: #052611;
        }
    }
}
